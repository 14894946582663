.login-form,.register-form {
    height: 450px;
    text-align: center;
    color: color("white");
    background-image: linear-gradient(to bottom, rgba(74, 74, 74, 0.95), #000000);
    background: no-repeat;
    background-size: cover;
    background-color: color("white");
  
    form,
    .btn {
      text-align: left;
    }
  
    @include media-breakpoint-down(sm) {
      nav>ol {
        background-color: rgb(0, 0, 0);
        opacity: 0.4;
      }
    }
  
    .form-content {
      text-align: center;
      color: color("black");
      background-color: color("white");
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 50px 105px 75px 105px;
      position: absolute;
      top: 30%;
      border-width: 0;
      border-left: 6px solid;
      border-image: linear-gradient(to bottom, color("gold"), #e0c548 47%, color("gold")) 1 100%;
  
      @include media-breakpoint-up(md) {
        width: 50%;
      }
  
      @include media-breakpoint-down(sm) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
  
      h1 {
        font-weight: 300;
        @include font-size(48);
      }

      h1,
      .description {
        margin-bottom: 48px;
      }
  
      @include media-breakpoint-up(md) {
        min-width: 700px;
      }
  
      @include media-breakpoint-down(sm) {
        width: 90%;
        padding: 30px 25px 40px 25px;
  
        h1,
        .description {
          margin-bottom: 24px;
        }
      }
  
      .btn.btn--arrow {
        min-width: unset;
      }
  
      .border-bottom {
        border-bottom: 1px solid color("warm-grey");
      }
  
      .form-check-label {
        vertical-align: -webkit-baseline-middle;
      }
  
      .btn--gradient-light {
        text-align: center;
      }
  
      .form-control:focus {
        border-color: unset;
        -webkit-box-shadow: unset;
        box-shadow: unset;
      }
  
      input[type="email"],
      input[type="password"] {
        color: color("warm-grey");
  
        &:focus {
          border-bottom: 2px solid black;
          outline: 0;
        }
      }
    }
  }
  