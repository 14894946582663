  .sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid var(--base-dark);
    box-shadow: 0 .25rem .25rem var(--base-shadow);
    will-change: transform;
    transform: translateY(0);
    transition: transform .4s ease-out;
  }

  .sticky-header-hidden {
    transform: translateY(-100%);
  }
