.navbar__mobile {
  padding: 20px 15px 15px 15px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 300;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  #mobileAccordion{
    padding-bottom: 100px;
  }

  // when the searchbox should show on page load (global search page)
  &.searchbox-static {
    flex-wrap: wrap;
    padding-bottom: 0;

    .btn-search-magnify {
      display: none;
    }

    .mobile-search-icon {
      @extend %mobile-search-open;
    }

    .mobile-search-container {
      position: static;
      display: block;
    }
  }

  // when the search container has been activated
  &.search-active {
    .mobile-search-icon {
      //@extend %mobile-search-open;
    }
    .btn-search-magnify {
      background-size: contain;
      height: 30px;
      width: 30px;
    }
  }

  // mobile search icon area
  .mobile-search-icon {
    
    .btn-search-magnify {
      width: 17px;
      height: 17px;
      background-size: contain;
      padding: 0;
    }
  }

  .navbar-brand,
  .container--image {
    img, svg {
      width: 100%;
      
      @include media-breakpoint-down(md) {
         max-width: 200px;
      }
    }
    z-index: 30;
  }

  .container--image {
    margin-top: -4px
  }

  .card {
    background-color: theme-color("dark");
    border-width: 1px 0px 1px 0px;
    border-color: rgba(139, 138, 138, 0.4);
    text-align: left;
    border-radius: 0px;

    .card-header {
      padding-left: 0px;
    }

    .card-body {
      border-top: 1px solid color("warm-gray");
      padding-bottom: 1rem;

      .mobile-sub-section {
        margin-bottom: 1.5rem;

        .mobile-sub-header {
          display: block;
        }

        ul {
          margin-top: 0.5rem !important;
          margin-left: 1.5rem !important;

          li {
            line-height: 2.5;
          }

          .mobile-sub-section-third {
            ul {
              margin-top: 0px !important;

              a {
                margin-bottom: 0px;

                li {
                  min-height: unset;
                }
              }
            }
          }
        }
      }
    }

    .mobile--regions {
      margin-bottom: 25px;

      .card {
        background-color: #303030 !important;

        .card-header {
          border-bottom: 1px solid rgba(139, 138, 138, 0.14);
        }

        .card-body {
          padding: 25px 40px 25px 40px;
        }

        .mobile--regions-label {
          color: color("light-grey") !important;
          @include font-size(15);
          margin-left: 12px;
          font-style: italic;
        }

        .mobile-link {
          @include font-size(15);
          line-height: 35px;
        }
      }
    }
  }

  //Adding the Plus Minus icons
  .card-header.mobile-expandable>h5>button:before,
  .card-header>h5>button.collapsed:before {
    position: absolute;
    right: 10px;
    pointer-events: all;
    color: color("white");
    padding-right: 5px;
  }

  .card-header.mobile-expandable>h5>button:before {
    content: url(/images/menu-icons/minus.svg);
  }

  .card-header.mobile-expandable>h5>button.collapsed:before {
    content: url(/images/menu-icons/plus.svg);
  }

  .btn-mobile-logout {
    width: 100%;
    height: 39px;
    padding-top: 15px;
  }

  .btn-link {
    color: color("white");
  }

  .mobile-link {
    @include font-size(13);
    display: block;
    color: color("white") !important;
    // margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .copyright {
    @include font-size(12);
    color: #b8b8b8;
    margin-top: 30px;
  }

  // Mobile Public Styles
  .public-button-container {
    a {
      width: 100%;
    }
  }

  //header mobile auth style
  .edition--container {
    padding: 20px;
    background-color: color("white");
  }

  .edition--select {
    select {
      display: inline-block;
      width: 75%;
      border-radius: 0px;
      padding: 12px;
      height: 40px !important;
      @include font-size(12);
    }

    label {
      font-weight: bold;
      @include font-size(12);
    }
  }

  .auth--logout {
    padding: 20px;
    background-color: color("white");

    button {
      width: 100%;
      background-image: linear-gradient(to bottom, #f6f6f6, #f4f4f4);
      border: none;
      padding: 20px 15px 20px 15px;
      @include font-size(15);
      color: color("black");
    }
  }

  .auth--collapse {
    color: color("black") !important;

    display: flex;
    align-items: center;
  }

  a.auth--collapse{
    width: 80%;
  }

  .auth--list {
    list-style: none;
    margin-bottom: 0px;

    li {
      margin-bottom: 15px;
    }

    li:last-of-type {
      margin-bottom: 0px;
    }

    li>a {
      color: color("black");
      font-weight: normal;
      @include font-size(15);
    }
  }
}

.overlay {
  position: fixed;
  background: theme-color("dark");
  top: 0;
  margin-top: 70px;

  .overlay-menu {
    position: relative;
    height: 70%;
    top: 35%;
    transform: translateY(-50%);
    @include font-size(50);
    font-weight: 400;
    text-align: center;
    padding: 0px 15px 0px 15px;
    z-index: 45;
  }

  &.overlay--public {
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    overflow: hidden;
    z-index: 15;

    &.open {
      opacity: 1;
      visibility: visible;
      height: 100%;
      overflow-y: auto;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 100%;

      li {
        display: block;
        height: 25%;
        // height: calc(100% / 4);
        // min-height: 50px;
        position: relative;
        opacity: 1;

        a {
          display: block;
          position: relative;
          color: color("white");
          text-transform: none !important;
          text-decoration: none;
          overflow: hidden;

          &:hover:after,
          &:focus:after,
          &:active:after {
            width: 100%;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0%;
            transform: translateX(-50%);
            height: 3px;
            background: color("white");
            transition: 0.35s;
          }
        }
      }
    }

    .btn-link:hover {
      text-decoration: none;
    }

    .mobile__header {
      @include font-size(15);
      text-transform: uppercase;
      letter-spacing: 2.08px;
    }

    .mobile__header--sub {
      @include font-size(13);
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 1.91px;

      ul {}
    }

    .mobile-link {
      font-weight: normal !important;
      letter-spacing: 1px;
    }
  }

  &.overlay--auth {
    left: 100%;
    width: 75%;
    transition: all 0.5s;
    overflow: hidden;
    z-index: 15;
    height: 100%;
    overflow-y: auto;

    .overlay-menu {
      padding-left: 0px;
      padding-right: 0px;
    }

    .card {
      background-color: color("white");

      .card-header {
        background-color: color("white");
      }

      .menu--svg{
        margin-top: -2px;
        margin-right: 1.5rem;
      }
    }

    .card--auth-active {
      background-color: color("pale-grey") !important;

      .card-header,
      .card-body {
        background-color: color("pale-grey") !important;
      }

      .card-header {
        padding-left: 13px !important;
        border-left: 2px solid color("gold") !important;
        border-bottom: none;
      }

      .card-body {
        padding: 0px 30px 30px 30px !important;
      }

      .auth--list {
        border-top: 1px solid color("lighter-grey");
      }

      .auth--list li:first-of-type {
        margin-top: 30px;
      }

      .menu--svg {
        stroke: color("gold");
        fill: color("gold");

        path {
          color: color("gold");
          stroke: color("gold");
        }

        g {
          stroke: color("gold");
        }
      }

      .experiences-icon.menu--svg {
        path {
          fill: color("gold");
        }
      }
    }

    .auth--expandable {
      transition: all 0.3s;
      position: absolute;
      right: 20px;
      top: 32px;
      background: transparent;
      border: none;
    }

    .auth--expandable-disabled {
      pointer-events: none;
    }

    .auth--expandable-flip {
      transform: rotate(180deg);
    }

    .card-header {
      padding-left: 15px;
    }

    .card-body {
      padding: 30px !important;
    }

    .author--container-sidebar {
      text-align: center;

      .profile-photo {
        width: 91px;
        height: 91px;
        margin: 0 auto;
      }

      .author-email {
        color: color("warm-grey");
        @include font-size(14);
      }

      .author-name {
        @include font-size(16px);
        width: 100%;
        text-align: center;
      }

      .account-item {
        padding: 20px 20px;
        @include font-size(16px);
      }
    }

    &.open {
      height: 100%;
      overflow-y: auto;
      left: 25%;
    }
  }
}

.hamburger--mobile {
  position: inherit;
  margin-top: 3px;
  height: 27px;
  width: 30px;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: color("white");
    }

    .middle {
      opacity: 0;
      background: color("white");
    }

    .bottom {
      transform: translateY(-6px) translateX(0) rotate(-45deg);
      background: color("white");
      width: 100% !important;
    }
  }

  span {
    background: color("white");
    border: none;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 8px;
      width: 75%;
    }

    &:nth-of-type(3) {
      top: 16px;
      width: 50%;
    }
  }
}

//mobile footer

.mobile-footer {
  margin-top: 50px;
  text-align: center;

  .mobile-link {
    @include font-size(15);
    text-transform: unset;
    margin-bottom: 30px;
  }
}

%mobile-search-open {
  order: 3;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  margin-top: 10px;
  margin-left: -15px;
  margin-right: -15px !important;
}

//Mobile Flyout

.flyout-overlay-mobile {
  display: none;
}

.flyout-overlay-mobile.overlay-active {
  display: block;
  z-index: 90;
  position: absolute;
  height: 100%;
  width: 100%;
  background: color("black");
  opacity: 0.65;
}

.freeze-body {
  overflow: hidden;
  touch-action: none;
  position: relative;
}