//General Navbar Styles
.navbar {
  z-index: 1000;
  background-color: theme-color("dark");
  // padding: 0px 35px 0px 93px;

  a {
    color: theme-color("light");
  }
}

//Main Navbar
.navbar__main {
  position: sticky;
  top: 0;
  height: $desktop_header_height;
  z-index: 100;
  margin-top: 0px;
  transition: all 0.3s;
  padding: 0 40px 0 40px;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .nav-link__main {
    letter-spacing: 2px;
  }

  .navbar-nav {
    display: flex;
    align-items: center;
  }

  .nav-item {
    margin-right: 36px;
  }

  a svg {
    width: 100%;
  }
}

.megamenu {
  position: static;

  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .dropdown-menu {
    padding: 0px;
    margin: 0px;
    border: none;
    width: 100%;

    .dropdown__list {
      -webkit-transition: opacity 0.5s;
      transition: opacity 0.5s;
      margin-right: 3rem;

      .dropdown__header {
        @include font-size(14);
        letter-spacing: 3.53px;
        opacity: 0.67;
        transition: opacity 0.3s;

        &::after {
          content: url(/images/menu-icons/chevron-right-white.svg);
          position: absolute;
          right: 15px;
        }
      }

      .dropdown__list--sub {
        border-top: 1px solid color("white");
        padding-top: 5px;
        margin-top: 13px;
        padding-left: 0;
        list-style: none;
        white-space: nowrap;

        .dropdown__list--link {
          opacity: 0.67;
          transition: opacity 0.3s;

          a {
            font-weight: normal;
          }
        }

        .nav-link {
          padding-left: 0px;
          line-height: 30px;
        }
      }
    }

    .dropdown--bottom-border {
      width: 100%;
      height: 1px;
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(to right, #bd9b08, #e0c548 47%, #bd9b08);
      border-image-slice: 1;
    }
  }

  // caret override
  .dropdown-toggle::after {
    border-top: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-left: 0.25em solid transparent;
  }
}

.dropdown-unfade {
  opacity: 1 !important;
}

//Add yellow gradient border on link active
.dropdown.show .dropdown__link {
  padding-top: 3px;
  padding-bottom: 15px;
  border-bottom: 2px solid #bd9b08;
}

///For Sticky Header JS

.sticky {
  position: fixed;
  top: 0;
  margin-top: 0px;
  z-index: 10;
  width: 100%;
}

.sticky--utility {
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 10;
  width: 100%;
  transition: all 0.3s;
}

.navbar__main--adjust {
  transition: all 0.3s;
  margin-top: 36px;
}

.form-group.search-bar {
  display: flex;
  border-radius: 0;
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.search-bar>input {
  border-radius: 0px;
  border: 0;
  height: 60px;
}

.btn--searchbar {
  background-color: color("light-grey");
  height: 60px;
  width: 60px;
}

.header--search-form {
  position: absolute;
  right: 20px;
  width: 50%;
}
