.btn {
  border-radius: 0;
  border: none;
  padding: 20px;
  cursor: pointer;
  width: 100%;        // do not change this, buttons should always span 100% of it's container unless overriden at a component level

  &.btn--arrow,
  .btn--arrow__previous {
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 21px 19px 20px 20px;
    line-height: 1;
    @include font-size(15);

    @include media-breakpoint-between(xs, sm) {
      text-align: left;
    }

    @include media-breakpoint-up(sm) {
      @include font-size(18);
    }

    &::after {
      content: '';
      margin: 4px 10px 0 0;
      width: 8px;
      height: 8px;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-out;

      @include media-breakpoint-up(sm) {
        margin-top: 6px;
      }
    }

    &:hover {
      background-color: #FFF;
      box-shadow: 12px 20px 18px 0 rgba(0, 0, 0, 0.05);

      &::after {
        transform: translateX(4px) rotate(-45deg);
      }
    }

    &.btn--gradient-dark {
      &::after {
        border-right: 2px solid color("gold");
        border-bottom: 2px solid color("gold");
      }
    }

    span {
      vertical-align: -webkit-baseline-middle;
    }
  }

  &.btn--arrow {
    &::after {
      float: right;
      border-right: 2px solid $body-color;
      border-bottom: 2px solid $body-color;
    }
  }

  &.btn--arrow__previous {
    &::after {
      float: left;
      border-left: 2px solid $body-color;
      border-top: 2px solid $body-color;
      border-right: none;
      border-bottom: none;
    }
  }

  &.btn--cog {
    width: 100%;
    border: none;
    padding: 21px 19px 20px 20px;
    line-height: 1;
    @include font-size(15);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;

    @include media-breakpoint-between(xs, sm) {
      text-align: left;
    }

    &::after {
      content: '';
      position: absolute;
      right: 20px;
      margin: 0;
      width: 19px;
      height: 19px;
      transition-duration: 0.3s;
      transition-property: transform;
      transition-timing-function: ease-out;
      background: url(/images/buttons/cog.svg) no-repeat;
      background-size: contain;
    }

    &:hover {
      &::after {
        transform: rotate(60deg);
      }
    }

    span {
      vertical-align: -webkit-baseline-middle;
    }
  }

  &.btn--save {
    width: 100%;
    border: none;
    padding: 21px 19px 20px 20px;
    line-height: 1;
    position: relative;
    @include font-size(15);

    &::after {
      content: url(/images/buttons/save.svg);
      position: absolute;
      right: calc(8% + 8.5px);
      margin: 0;
      width: 17px;
      height: 17px;
      transition: transform 0.3s ease-out;
    }

    span {
      vertical-align: -webkit-baseline-middle;
    }
  }

  &.btn--download {
    transform: perspective(1px) translateZ(0);

    &:hover {
      img {
        animation-name: hvr-icon-down;
        animation-duration: 0.75s;
        animation-timing-function: ease-out;
      }
    }

    img {
      transform: translateZ(0);
    }
  }

  &.btn--utility-light {
    @extend %utility-base;

    background-image: linear-gradient(to top, #aeaeae, #ffffff);
  }

  &.btn--utility-gray {
    @extend %utility-base;

    background-image: linear-gradient(to top, #f6f6f6, #f4f4f4);
  }

  &.btn--utility-dark {
    @extend %utility-base;

    color: #FFF;
    background-image: linear-gradient(to top, rgba(80, 80, 80, 0.54), rgba(104, 103, 103, 0.72));
  }

  &.btn--gradient-light {
    @extend %utility-large;

    background-image: linear-gradient(to bottom, #f6f6f6, #f4f4f4);
  }

  &.btn--gradient-dark {
    @extend %utility-large;

    color: #FFF;
    background-image: linear-gradient(to top, #000000, #333333);
  }

  &.full {
    width: 100%;
    height: 100%;
  }

  // buttons used within a bootstrap input-group
  .input-group & {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}

%utility-base {
  padding: 7px 30px 8px 30px;
  @include font-size(12);
  line-height: normal;
}

%utility-large {
  @include font-size(15);
  line-height: normal;
}

.arrow-thin {
  display: block;
  width: 50px;
  margin: 50px auto;
  height: 0;
  border-bottom: 1px solid color("gold");

  &:before {
    content: '';
    width: 0;
    height: 0;
    display: inline-block;
    transform: translate(28px, -10px);
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid color("gold");
  }
}

// For two buttons that are full width on md and half-width on small
.button--split__mobile {
  .btn {
    &:first-of-type {
      margin-right: 10px;
    }

    @include media-breakpoint-down(sm) {
      display: inline-block;
      width: calc(49% - 5px) !important;

      &:nth-of-type(2) {
        float: right;
      }
    }
  }
}

//Split on desktop, full width on mobile
.button--split__desktop {
  .btn {
    &:first-of-type {
      margin-right: 10px;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      display: inline-block;
      width: calc(49% - 5px) !important;

      &:nth-of-type(2) {
        float: right;
      }
    }
  }
}

.btn--unset {
  appearance: none;
  -webkit-appearance: unset;
  border: unset;
  padding: unset;
  margin: unset;
}