/********************************************************
        Font-Family: Kantar Brown
********************************************************/
@font-face {
    font-family: 'Kantar Brown';
    src: url('/fonts/KantarBrown-ThinItalic.woff2') format('woff2'),
        url('/fonts/KantarBrown-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Kantar Brown';
    src: url('/fonts/KantarBrown-Bold.woff2') format('woff2'),
        url('/fonts/KantarBrown-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Kantar Brown';
    src: url('/fonts/KantarBrown-LightItalic.woff2') format('woff2'),
        url('/fonts/KantarBrown-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Kantar Brown';
    src: url('/fonts/KantarBrown-Regular.woff2') format('woff2'),
        url('/fonts/KantarBrown-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kantar Brown';
    src: url('/fonts/KantarBrown-Thin.woff2') format('woff2'),
        url('/fonts/KantarBrown-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Kantar Brown';
    src: url('/fonts/KantarBrown-Italic.woff2') format('woff2'),
        url('/fonts/KantarBrown-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Kantar Brown';
    src: url('/fonts/KantarBrown-Light.woff2') format('woff2'),
        url('/fonts/KantarBrown-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Kantar Brown';
    src: url('/fonts/KantarBrown-BoldItalic.woff2') format('woff2'),
        url('/fonts/KantarBrown-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

/********************************************************
        Font-Family: Kantar Brown Cyr
********************************************************/
@font-face {
    font-family: 'Kantar Brown Cyr';
    src: url('/fonts/KantarBrownCyrWeb-Italic.woff2') format('woff2'),
        url('/fonts/KantarBrownCyrWeb-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Kantar Brown Cyr';
    src: url('/fonts/KantarBrownCyrWeb-Light.woff2') format('woff2'),
        url('/fonts/KantarBrownCyrWeb-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Kantar Brown Cyr';
    src: url('/fonts/KantarBrownCyrWeb-Regular.woff2') format('woff2'),
        url('/fonts/KantarBrownCyrWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kantar Brown Cyr';
    src: url('/fonts/KantarBrownCyrWeb-Bold.woff2') format('woff2'),
        url('/fonts/KantarBrownCyrWeb-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Kantar Brown Cyr';
    src: url('/fonts/KantarBrownCyrWeb-LightItalic.woff2') format('woff2'),
        url('/fonts/KantarBrownCyrWeb-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Kantar Brown Cyr';
    src: url('/fonts/KantarBrownCyrWeb-BoldItalic.woff2') format('woff2'),
        url('/fonts/KantarBrownCyrWeb-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Kantar Brown Cyr';
    src: url('/fonts/KantarBrownCyrWeb-Thin.woff2') format('woff2'),
        url('/fonts/KantarBrownCyrWeb-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Kantar Brown Cyr';
    src: url('/fonts/KantarBrownCyrWeb-ThinItalic.woff2') format('woff2'),
        url('/fonts/KantarBrownCyrWeb-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}