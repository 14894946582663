.sidebar--desktop {
  background-color: color("white");
  width: 80px;
  position: fixed;
  left: 0px;
  height: calc(100vh - #{$desktop_header_height});
  z-index: 1010;

  a {
    display: block;
    color: unset;
    font-weight: unset;
  }
}

.sidebar--desktop>ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0;
  list-style-type: none;
}

.sidebar--flyout li {
  list-style-type: none;
}

.sidebar--tile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 22.5px 5px 12px 5px;
  width: 100%;
  border-bottom: 0.5px solid #f8f8f8;
  text-align: center;
  position: relative;
  white-space: nowrap;
  min-height: 0;

  &.expandable {
    cursor: pointer;
  }

  &.expandable::after {
    content: url(/images/menu-icons/chevron-right.svg);
    position: absolute;
    right: 12px;
    top: 31%;
  }

  &:hover {
    background-color: #f9f8f9;
  }

  >a {
    width: 100%;
  }
}

.sidebar--tile_active {
  border-width: 0;
  border-right: 2px solid;
  border-right-color: color("gold");
  background-color: color("pale-grey");
  padding-left: 7px;

  .menu--svg {
    path {
      fill: color("gold");
    }
  }

  .experiences-icon.menu--svg {
    path {
      fill: color("gold");
    }
  }
}

.sidebar--flyout {
  position: fixed;
  left: -315px;
  transition: all 0.3s;
  z-index: 1001 !important;
  background-color: color("pale-grey");
  height: 100vh;
  width: 315px;
  text-align: left;
  padding: 40px;
  cursor: auto !important;

  .card,
  .card-header {
    background-color: color("pale-grey");
    padding-left: 0px;
    padding-right: 0px;
  }

  .card-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.flyout-overlay {
  display: none;
}

.sidebar--flyout.active {
  left: 80px;
}

.flyout-overlay.overlay-active {
  display: block;
  z-index: 95;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: color("black");
  opacity: 0.65;
}

.flyout-wrapper {
  position: absolute;
  width: 100%;
}

.wrapper-inactive {
  pointer-events: none;
}

.flyout-section {
  list-style-type: none;

  .author-panel {
    float: unset;
    padding-left: unset;
    padding-right: unset;
    margin-right: unset;
    margin-bottom: unset;

    .author-container {
      text-align: center;
      align-items: center;

      img {
        display: inline;
        height: 100px;
        width: 100px;
      }

      .author-name {
        @include font-size(17);
        font-weight: bold;
      }

      .author-email {
        @include font-size(14);
        color: color("warm-grey");
      }

      .author-logout {
        background-color: transparent;
        border: 1px solid color("pale-lavendar");
        @include font-size(14);
        padding: 10px 15px 10px 10px;
        width: 45%;
        transition: border 0.3s;

        &:hover {
          border: 1px solid black;
        }
      }
    }
  }
}

.flyout-section>li {
  margin-bottom: 20px;
}

.flyout-section>li>a {
  font-size: 15px;
  font-weight: normal;
  color: #3c4341;

  &:hover {
    text-decoration: underline;
  }
}

.flyout-section li.flyout-header {
  border-bottom: 1px solid color("lighter-grey");
  padding-bottom: 10px;
  font-size: 18px !important;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.91px;
  color: #020202;

  a {
    display: block;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;

    &::after {
      content: '';
      margin: 4px 10px 0 0;
      width: 8px;
      height: 8px;
      transform: rotate(-45deg);
      float: right;
      border-right: 2px solid #303030;
      border-bottom: 2px solid #303030;
    }
  }
}

.sidebar-icon {
  margin-bottom: 10px;
  width: 100%;
}

.icon-label {
  @include font-size(11);
  margin-bottom: 0px;
  letter-spacing: -0.2px;
}

////////////For Mobile

.sidebar--mobile {
  position: fixed;
  bottom: 0px;
  z-index: 100;
  background-color: color("white");
  width: 100%;

  .sidebar-icon {
    margin-bottom: 0px;
    text-align: center;
  }
}

.mobile-tile {
  padding: 15px;
}

.mobile-tile.active {
  border-width: 0;
  border-top: 2px solid;
  border-top-color: color("gold");
  background-color: color("pale-grey");
}

.sidebar--downarrow {
  content: url(/images/menu-icons/chevron-down.svg);
}

.flyout-mobile {
  position: absolute;
  bottom: 0px;
}

.flyout-mobile {
  position: fixed;
  background: color("white");
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  z-index: 20;

  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    overflow-y: auto;
    margin-top: 65px;
  }
}

option:disabled {
  color: color("warm-grey");
}

.account-item {
  border-top: 1px solid color("pale-lavendar");
  text-align: center;
  padding: 30px 30px;

  a {
    color: color("black");
    font-weight: normal;
  }
}

.account-item:last-of-type {
  border-bottom: 1px solid color("pale-lavendar");
}
