.component {

  // exclude margin-bottom from p tags when not used within a rich-text component
  &:not(.rich-text) {

    ul,
    li:not([class*="col"]),
    dl {
      list-style: none outside none;
      padding: 0;
    }
  }
}

article {
  img {
    max-width: 100%;
  }
}
