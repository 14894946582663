form {
  .form-control {
    @include font-size(15);
    color: color("warm-grey");

    -webkit-appearance: none;
    -moz-appearance: none;

    border-radius: 0px !important;
    padding: 12px 15px 7px 15px;
    margin-bottom: 7px;
    border: 1px solid #ced4da;

    &:focus {
      -webkit-box-shadow: unset;
      box-shadow: unset;
      border-color: #ced4da;
      border-bottom: 2px solid black;
      padding-bottom: 5px !important;
      outline: 0;
    }

    &.error:focus {
      border-bottom: 2px solid color("error");
    }

    &:not(textarea) {
      height: 50px;
    }
  }

  .form-check-label {
    vertical-align: -webkit-baseline-middle;
    margin-left: 5px;
  }

  input,
  textarea,
  select {
    &.error {
      border-bottom: 2px solid color("error");
      padding-bottom: 5px;
    }
  }

  input {
    padding-left: 10px !important;
  }

  select {
    @include media-breakpoint-down(md) {
      padding-left: 10px !important;
    }
  }
}

/************************************************************
  Field Validation
************************************************************/
.field-validation-error {
  color: color("error");
  display: block;
  position: relative;

  p {
    color: inherit;
  }

  &:after {
    content: url(/images/forms/text-warning-icon-red.svg);
    position: absolute;
    right: 0px;
    top: 2px;
  }

  // when form or a single form-group calls for absolute positioned error messages
  .validation-absolute & {
    position: absolute;
    width: 100%;
  }
}

.parsley-errors-list {
  opacity: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  transition: all .3s ease-in;

  &.filled {
    opacity: 1;
  }
}

/************************************************************
  Custom Checkbox Styling
************************************************************/

.custom-control-label::before {
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.54);


}

.custom-control-label.custom-control-label.insight-center {
  display: flex;
}

.custom-checkbox {

  .custom-control-label::before,
  .custom-control-label::after {
    margin-right: 5px;
    height: 18px;
    width: 18px;
  }

  .custom-control-label {
    &::before {
      border-radius: 0.15rem;
    }

    &.insight-center {
      &::before, &::after {
        top: -2px !important;
      }
    }
  }

  .custom-control-input {
    
    &:checked {
      ~.custom-control-label {
        &::before {
          background-color: color("gold") !important;
        }
        &::after {
          background-image: url("/images/forms/check.svg");
          background-size: 14px;
        }
      }
    }

    // disabled
    &[disabled] {
      ~.custom-control-label {
        &::before {
          background-color: rgba(0, 0, 0, 0.54) !important;
          border: none;
        }
      }
    } 
  }
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color:
    rgba(0, 0, 0, 0.54) !important;
}

.custom-control-input:active {
  background-color: none !important;
  box-shadow: none !important
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: color("gold") !important;
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: white;
}




//If we want to manipulate active state styles
.custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: white !important;
  border-color: rgba(0, 0, 0, 0.54) !important;
}

.custom-check-text {
  margin-left: 5px;

}

//Insight Center Mobile Checkbox Styling

.insight-checkbox {
  @include media-breakpoint-down(md) {
    margin-left: 1rem;
  }
}

/************************************************************
  Custom Radio Styling
************************************************************/
.custom-radio {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;

  &:hover {
    input {
      ~.radio {
        border-color: darken(color("warm-grey"), 20%);
      }
    }
  }

  // hide the browser's default radio button
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    // when the radio button is checked
    &:checked {
      ~.radio {
        border-color: color("gold");

        &:after {
          display: block;
        }
      }
    }
  }

  .radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: inherit;
    border-radius: 50%;
    border: 2px solid color("warm-grey");

    &:after {
      content: "";
      position: absolute;
      display: none;

      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: color("gold");
    }
  }
}

//Hubspot Forms

.hbspt-form {

  fieldset {
    max-width: unset !important;

    div.input {
      margin-right: 0 !important;
    }
  }

  .hs-form-booleancheckbox {
    position: relative;

    input {
      position: absolute;
      top: 2px;
    }
  }

  fieldset.form-columns-2 {

    div:first-of-type {
      @include media-breakpoint-up(md) {
        margin-right: 30px;
      }

    }

    div.input {
      margin-right: 0;
    }

    .hs-form-field {
      @include media-breakpoint-up(md) {
        width: calc(50% - 15px) !important;
      }

    }

    .hs-fieldtype-textarea {
      width: 100% !important;
    }

    input {
      font-size: 0.9375rem;
      color: #8b8a8a;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 0px !important;
      padding: 12px 15px 7px 15px;
      margin-bottom: 7px;
      border: 1px solid #ced4da;
      width: 100%;

      &:focus {
        -webkit-box-shadow: unset;
        box-shadow: unset;
        border-color: #ced4da;
        border-bottom: 2px solid black;
        padding-bottom: 5px !important;
        outline: 0;
      }

      &.error:focus {
        border-bottom: 2px solid color("error");
      }

      &:not(textarea) {
        height: 43px;

        @include media-breakpoint-down(sm) {
          width: 100% !important;
        }
      }
    }
  }



  textarea {
    width: 100% !important;
    min-height: 285px;
    margin-bottom: 20px;
  }

  ul.hs-error-msgs {
    list-style: none;
    padding-left: 0;

    .hs-error-msg {
      color: color("error");
      display: block;
      position: relative;

      &:after {
        content: url(/images/forms/text-warning-icon-red.svg);
        position: absolute;
        right: 0px;
        top: 2px;
      }
    }
  }

  .legal-consent-container {
    ul.inputs-list {
      list-style: none;
      padding: 0;
    }
  }

  div.hs-submit {
    width: 50%;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 20px;
    }

    input[type=submit] {
      width: 100%;
      background-image: linear-gradient(to top, #000000, #333333);
      color: white;
      padding: 21px 19px 20px 20px;
    }
  }

  div.hs_topics_of_interest {
    ul {
      list-style: none;
      padding-left: 0;

      input {
        margin-right: 10px;
      }
    }
  }


  select {
    height: 50px;
    font-size: 0.9375rem;
    color: #8b8a8a;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0px !important;
    padding: 12px 15px 7px 15px;
    border: 1px solid #ced4da;
    width: 100% !important;
    margin-bottom: 5px;
  }
}

//International Phone Input

.iti.iti--allow-dropdown {
  width: 100%;
}

.iti__flag {
  background-image: url("/images/country-flags/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("/images/country-flags/flags@2x.png");
  }
}

.iti__selected-flag {
  height: 50px;
}

.hide-error {
  display: none;
}
