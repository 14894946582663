body {
  -webkit-font-smoothing: antialiased;
}

a {
  font-weight: bold;
  font-size: 0.875rem;

  @include media-breakpoint-up(md) {
    font-size: inherit;
  }

  &:active {
    color: color("gold");
  }

  &.link--secondary {
    color: color("black");

    &:hover,
    &:active {
      color: #000;
    }
  }
}

p {
  margin-bottom: 1.5rem;
  letter-spacing: normal;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.36;

  @include media-breakpoint-up(md) {
    line-height: 1.44;
    font-size: inherit;
  }

  &.lead {
    @include font-size(16);
    line-height: 21px;
    color: #303030;
    font-weight: 300;

    @include media-breakpoint-up(md) {
      @include font-size(21);
      line-height: 1.57;
      letter-spacing: -0.4px;
    }
  }

  &.small {
    line-height: 1.33;
    color: #252525;

    @include media-breakpoint-up(md) {
      @include font-size(15);
    }
  }
}

.label {
  @include font-size(14px);
  font-style: normal;
  line-height: 1.14;

  &.gray {
    color: #91908f;
  }

  @include media-breakpoint-down(md) {
    @include font-size(14px);
    line-height: 1.33;
  }

  &.caption {
    font-size: 13px !important;
    line-height: 1.23;
    color: #252525;

    @include media-breakpoint-up(md) {
      line-height: 1.38;
    }
  }
}

.desktop {
  &.large-body {
    @include font-size(21);
    font-weight: 300;
  }

  &.small-body {
    @include font-size(15);
    font-weight: normal;
  }
}

h1 {
  letter-spacing: -0.96px;
  color: #303030;

  @include media-breakpoint-down(md) {
    @include font-size(28);
    font-weight: 300;
  }
}

h2 {
  letter-spacing: -0.61px;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    @include font-size(24);
    letter-spacing: -0.4px;
  }
}

h3 {
  letter-spacing: -0.41px;

  &.header {
    line-height: 1.33;
  }

  @include media-breakpoint-down(md) {
    @include font-size(19);
    font-weight: 300;
    line-height: 1.26;
    letter-spacing: -0.26px;
  }
}

h4 {
  line-height: 1.2;

  @include media-breakpoint-down(md) {
    font-weight: 700;
    line-height: 1.44;
    @include font-size(16);
  }
}

h5 {
  line-height: 1.26;
  font-weight: 700;

  @include media-breakpoint-down(md) {
    @include font-size(15);
  }
}

.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: normal;
}

.copy {
  @include font-size(16);
  line-height: 1.25;

  @include media-breakpoint-down(md) {
    @include font-size(14);
    line-height: 1.36;
  }

  &.light {
    font-weight: 300;
    line-height: 1.44;
  }

  &.small {
    @include font-size(15);
    line-height: 1.33;
  }
}

ul {
  li {}
}

ul,
ol {
  li {
    font-weight: 300;
    font-size: 0.875rem;
    margin-bottom: .5rem;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
}
