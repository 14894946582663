#footer {
  background-color: #000;

  @include media-breakpoint-up(md) {
    background-color: unset;
  }

  .container--footer {
    padding: 60px 15px 18px 15px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      padding: 56px 55px 47px 43px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .nav-links {

    @include media-breakpoint-between(xs, md) {
      order: -1;
    }

    @include media-breakpoint-up(lg) {
      overflow: hidden;
      margin-left: auto;
      margin-right: 45px;
    }

    ul {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: -1px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    li {
      flex-grow: 1;
      flex-basis: auto;
      padding: 0 1.5em;
      text-align: center;
      margin-top: 25px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        border-left: 1px solid #8f8e8d;

        &:first-of-type {
          margin-left: 0;
          border-left: none;
        }
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .logo {
    margin: 40px 0 50px;

    @include media-breakpoint-up(lg) {
      margin: 0;
      max-width: 20%;
    }

    svg {
      height: 24px;
      width: auto;

      .non-gold-text {
        @include media-breakpoint-between(xs, sm) {
          fill: color("white");
        }
      }
    }
  }

  a {
    @include font-size(15);
    color: #FFF;
    font-weight: bold;

    &:hover {
      color: color("gold");
    }

    @include media-breakpoint-up(md) {
      color: #8f8e8d;
      font-weight: normal;
    }
  }

  span {
    color: #8b8a8a;
    font-size: 12px;
    font-weight: 400;

    @include media-breakpoint-up(md) {
      font-weight: normal;
      @include font-size(14);
    }
  }

  &.bg-light {

    a,
    span,
    p {
      color: comp-color("footer-authenticated");
    }
  }
}
